import { Actions, ofType } from "@ngrx/effects"
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { DashboardActionTypes, UpdateDashboardChartSuccess } from "../../actions/filters.actions"
import { DashboardChart } from "../../models/dashboard-chart"
import { GptService } from "../../services/gpt.service"
import { SegmentService } from "../../../core/services/segment.service"
import { Subscription } from "rxjs"

@Component({
  selector: 'twng-gpt',
  templateUrl: './gpt.component.html',
  styleUrls: ['./gpt.component.scss']
})
export class GptComponent implements OnDestroy, OnInit {
  @Input() canViewDebug = false
  @ViewChild('inputText') inputText;

  responseText = ""
  debugJSON = ""
  invalidText = ""
  isValid = true
  isLoading = false
  requiredError = false
  subs = new Subscription()
  charts: DashboardChart[]
  showDebug = false
  summarizeResults = false
  question = ""
  history = []
  maxChars = 2000

  exampleQuestions = [
    "What is the offer acceptance rate by department this year?",
    "Show me a table of candidates hired last month, including recruiter, source, and time to hire",
    "What are some reasons the offer acceptance rate fluctuated over the last 12 months?",
  ]

  constructor(private gpt: GptService, private cd: ChangeDetectorRef,
    private actions: Actions, private segmentService: SegmentService) { }

  ngOnInit(): void {
    this.segmentService.track('QUIN Page Viewed')
    this.subs.add(
      this.gpt.history().subscribe({
        next: (data) => {
          this.history = data
          this.cd.markForCheck()
        }
      })
    )

    this.subs.add(this.actions.pipe(
      ofType(
        DashboardActionTypes.UpdateDashboardChartSuccess
      ),
    ).subscribe((action: UpdateDashboardChartSuccess) => {
      this.charts =
        this.charts.map(
          chart => chart.id.toString() === action.payload.dashboard_chart.id ? action.payload.dashboard_chart : chart
        )

      this.cd.markForCheck()
    }))
  }

  handleSubmit(question) {
    if (question) {
      this.question = question
      this.requiredError = false
      this.showDebug = false
      this.isLoading = true
      this.charts = []

      this.subs.add(
        this.gpt.ask({question, summarize_results: this.summarizeResults}).subscribe({
          next: (data) => {
            this.segmentService.track('QUIN Question Asked', {question})
            this.inputText.nativeElement.textContent = ""
            this.responseText = data.response
            this.charts = data.charts
            this.debugJSON = data.debug
            this.isLoading = false
            this.isValid = data.is_valid
            this.invalidText = !data.is_valid ? 'Invalid question' : ''

            const chatMessageId = data.chat_message_id
            const historyIndex = this.history.findIndex(q => q.id === chatMessageId)
            if (historyIndex > -1) {
              const [currentQuestion] = this.history.splice(historyIndex, 1)
              this.history.unshift(currentQuestion)
            } else {
              this.history.unshift({created_at: new Date().toISOString(), message: question, id: chatMessageId})
            }

            this.cd.markForCheck()
          },
          error: () => {
            this.segmentService.track('QUIN Question Error', {question})
            this.invalidText = 'Error occurred processing question, try a different question or try again later'
            this.responseText = ""
            this.debugJSON = ""
            this.charts = null
            this.isLoading = false
            this.isValid = false
            this.cd.markForCheck()
          }
        })
      )
    } else {
      this.requiredError = true
    }
  }

  setQuestion(question) {
    this.inputText.nativeElement.textContent = question.substring(0, this.maxChars)
    this.inputText.nativeElement.focus()
    const sel = window.getSelection();
    sel.selectAllChildren(this.inputText.nativeElement);
    sel.collapseToEnd()
  }

  toggleShowDebug() {
    this.showDebug = !this.showDebug
  }

  toggleSummarizeResults() {
    this.summarizeResults = !this.summarizeResults
  }

  handleInputEvents(e) {
    if (e.code !== 'Backspace' && this.inputText.nativeElement.textContent.length >= this.maxChars) {
      e.preventDefault()
    }

    if (e instanceof ClipboardEvent && e.type === 'paste') {
      e.preventDefault()

      const clipboardData = e.clipboardData.getData('text/plain')
      const currentText = this.inputText.nativeElement.textContent
      this.setQuestion(`${currentText} ${clipboardData}`)
    }
  }

  deleteQuestion(questionId) {
    this.subs.add(
      this.gpt.destroyHistoryQuestion(questionId).subscribe({
        next: () => {
          this.history = this.history.filter(q => q.id !== questionId)
          this.cd.markForCheck()
        }
      })
    )
  }

  deleteAllHistory() {
    this.subs.add(
      this.gpt.destroyAllHistory().subscribe({
        next: () => {
          this.history = []
          this.cd.markForCheck()
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}

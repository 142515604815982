<ul #dashboardTopNav="ngbNav" [activeId]="mainNav" ngbNav class="nav nav-tabs app__tabs">

  <li [ngbNavItem]="'widget-library'">
    <a ngbNavLink
      routerLink="/dashboard/widget-library/{{analyticsData?.widget_library_tabs &&analyticsData.widget_library_tabs[0]?.id}}"
      class="fs-unmask">Widget Library</a>

    <ng-template ngbNavContent>
      <ul #widgetLibraryNav="ngbNav" [activeId]="subNav" ngbNav class="nav nav-tabs app__tabs sub-tabs">

        <li *ngFor="let tab of analyticsData.widget_library_tabs; trackBy:trackById" [ngbNavItem]="tab.id"
          [id]="tab.id">
          <a ngbNavLink routerLink="/dashboard/widget-library/{{tab.id}}" class="fs-unmask" [attr.data-intercom-target]="tab.name">
            {{ tab.name }}
          </a>

          <ng-template ngbNavContent>
            <ng-container *ngIf="
              analyticsData?.loaded &&
              analyticsData.widget_library_tabs_data &&
              analyticsData.widget_library_tabs_data[tab.id];
              else spinner">

              <section id="widget-library-{{tab.id}}" *ngIf="!analyticsData.widget_library_tabs_data[tab.id].is_qoh">
                <div
                  *ngFor="let section of ((analyticsData.widget_library_tabs_data || {})[tab.id].sections); trackBy:trackByIndex"
                  class="dashboard__grid--css">
                  <ng-container *ngFor="let chart of section.charts; trackBy:trackByDataSource">
                    <twng-chart-wrapper [chartFiltersOverride]="analyticsData.company_dashboard_filters" [chart]="chart"
                      [style]="chart?.is_custom_title ? 'background: transparent; box-shadow: none' : ''"
                      [ngClass]="dashboardWidgetClasses(chart)" [chartInitConfig]="chartInitConfig">
                    </twng-chart-wrapper>
                  </ng-container>
                </div>
              </section>

              <ng-container #qoh *ngIf="analyticsData.widget_library_tabs_data[tab.id].is_qoh">
                <div class="tw-flex tw-justify-center tw-p-20 tw-bg-brand">
                  <div class="tw-flex tw-bg-white tw-border tw-border-cq-gray-300 tw-rounded-md tw-max-w-[60%] 2xl:tw-max-w-[50%] blue-shadow">
                    <img src="/assets/analytics_banner.png" alt="analytics banner" width="316" height="220">
                    <div class="tw-flex tw-flex-col tw-justify-center tw-p-5">
                      <h1 class="tw-font-medium tw-text-cq-gray-800 tw-text-xl tw-my-2">
                        Redefine Hiring Success
                      </h1>
                      <p class="tw-text-cq-gray-600 tw-text-base tw-my-1">
                        Tracks Quality of Hire, performance and retention among your hiring cohorts.
                      </p>
                      <p class="tw-text-cq-gray-600 tw-text-base">
                        Identify insights that lead to improved interview processes.
                      </p>
                      <a href="mailto:customersuccess@crosschq.com" class="button--link tw-py-3">
                        Contact your CSM to learn more
                      </a>
                    </div>
                    </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
        </li>
      </ul>

      <div class="dashboard-tab-content" *ngIf="analyticsData?.loaded">
        <twng-widget-library-tab-header dashboardElementId="widget-library-{{subNav}}"
          [dashboardFilters]="analyticsData?.company_dashboard_filters"
          [extraLink]="analyticsData?.widget_library_tabs_data &&
                       analyticsData?.widget_library_tabs_data[widgetLibraryNav.activeId]?.extra_link">
        </twng-widget-library-tab-header>

        <div class="app__tab-outlet" [ngbNavOutlet]="widgetLibraryNav"></div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="'dashboards'">
    <a ngbNavLink routerLink="/dashboard/dashboards" class="fs-unmask">Dashboards</a>

    <ng-template ngbNavContent>
      <ul #dashboardsNav="ngbNav" [activeId]="subNav" ngbNav class="nav-tabs app__tabs sub-tabs">

        <ng-container *ngIf="analyticsData?.loaded">
          <li [ngbNavItem]="'mine'" *ngIf="appConfig.showMyDashboard() && analyticsData && isMineTabEnabled">
            <a ngbNavLink routerLink="/dashboard/dashboards/mine" class="fs-unmask">My Dashboard</a>

            <ng-template ngbNavContent>
              <ng-container *ngIf="analyticsData.my_dashboard_filters; else spinner">
                <section id="my_offers_and_hires">
                  <div *ngIf="!currentExternalUser" class="alert alert-warning fs-unmask">
                    No Greenhouse user was found matching your email address. My Dashboard cannot be shown.
                  </div>

                  <twng-widget-library-tab-header [dashboardElementId]="'my_offers_and_hires'"
                    [dashboardFilters]="analyticsData?.my_dashboard_filters" [panel]="myDashboard">
                  </twng-widget-library-tab-header>

                  <div class="dashboard__grid--css">
                    <!--
              These 4 stats boxes should be a group here in the DOM.
              Not in terms of wrapping a div around it, but just next to each other -->
                    <twng-dash-stats *ngIf="analyticsData.my_hires_total?.value" class="dashboard__grid-item"
                      label="My Hires" [value]="analyticsData.my_hires_total.value" bottomLine="IN PERIOD"
                      [ids]="analyticsData.my_hires_total?.offer_ids" [candidateDataType]="widgetDataTypes.HIRED" gridItemType="css">
                    </twng-dash-stats>

                    <twng-dash-stats *ngIf="analyticsData.my_offers_outstanding?.value" class="dashboard__grid-item"
                      label="My Outstanding Offers" bottomLine="TOTAL"
                      [ids]="analyticsData.my_offers_outstanding?.offer_ids" [candidateDataType]="widgetDataTypes.OFFER"
                      gridItemType="css" [value]="analyticsData.my_offers_outstanding.value">
                    </twng-dash-stats>

                    <twng-dash-stats *ngIf="analyticsData.my_open_jobs" class="dashboard__grid-item" label="My Open Jobs"
                      bottomLine="TOTAL" gridItemType="css" [value]="analyticsData.my_open_jobs.value">
                    </twng-dash-stats>

                    <twng-dash-stats *ngIf="analyticsData.my_time_to_offer?.value" class="dashboard__grid-item"
                      label="My Time to Offer" bottomLine="IN PERIOD" bottom="Days"
                      [ids]="analyticsData.my_time_to_offer?.offer_ids" [candidateDataType]="widgetDataTypes.OFFER" gridItemType="css"
                      [value]="analyticsData.my_time_to_offer.value">
                    </twng-dash-stats>

                    <twng-dash-stats *ngIf="analyticsData.my_time_to_hire?.value" class="dashboard__grid-item"
                      label="My Time to Hire" bottomLine="IN PERIOD" bottom="Days"
                      [ids]="analyticsData.my_time_to_hire?.offer_ids" [candidateDataType]="widgetDataTypes.HIRED" gridItemType="css"
                      [value]="analyticsData.my_time_to_hire.value">
                    </twng-dash-stats>

                    <twng-dash-stats *ngIf="analyticsData.my_time_to_fill" class="dashboard__grid-item"
                      [candidateDataType]="widgetDataTypes.HIRED" [ids]="analyticsData.my_time_to_fill.offer_ids"
                      label="My Time to Fill" bottomLine="IN PERIOD" bottom="Days" gridItemType="css"
                      [value]="analyticsData.my_time_to_fill.value">
                    </twng-dash-stats>

                    <twng-dash-stats *ngIf="analyticsData.my_time_to_start" class="dashboard__grid-item"
                      label="My Time to Start" bottomLine="IN PERIOD" bottom="Days" gridItemType="css"
                      [candidateDataType]="widgetDataTypes.HIRED" [value]="analyticsData.my_time_to_start.value">
                    </twng-dash-stats>

                    <div class="dashboard__grid-item"
                      *ngIf="analyticsData.my_hires_total && analyticsData.my_offers_rejected">
                      <div class="widget
                        stats
                        stats--interviews">
                        <header>
                          <div class="header__info-icon" data-html2canvas-ignore [openDelay]="0"
                            ngbPopover="Offer Acceptance rate is the total number of offers accepted divided by the total number of offers which were marked as hired or declined within the selected date range. Offers that are still open are not included in this calculation.">
                            <!-- Boxicons bx:bx-info-circle -->
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.75 6.75H10.25V5.25H8.75V6.75ZM9.5 15C6.1925 15 3.5 12.3075 3.5 9C3.5 5.6925 6.1925 3 9.5 3C12.8075 3 15.5 5.6925 15.5 9C15.5 12.3075 12.8075 15 9.5 15ZM9.5 1.5C8.51509 1.5 7.53982 1.69399 6.62987 2.0709C5.71993 2.44781 4.89314 3.00026 4.1967 3.6967C2.79018 5.10322 2 7.01088 2 9C2 10.9891 2.79018 12.8968 4.1967 14.3033C4.89314 14.9997 5.71993 15.5522 6.62987 15.9291C7.53982 16.306 8.51509 16.5 9.5 16.5C11.4891 16.5 13.3968 15.7098 14.8033 14.3033C16.2098 12.8968 17 10.9891 17 9C17 8.01509 16.806 7.03982 16.4291 6.12987C16.0522 5.21993 15.4997 4.39314 14.8033 3.6967C14.1069 3.00026 13.2801 2.44781 12.3701 2.0709C11.4602 1.69399 10.4849 1.5 9.5 1.5V1.5ZM8.75 12.75H10.25V8.25H8.75V12.75Z"
                                fill="black" />
                            </svg>
                          </div>
                          <div class="header__label">My Offer Acceptance</div>
                          <div class="header__actions-button" data-html2canvas-ignore>
                            <twng-chart-drop-down-menu chartType="number-box">
                            </twng-chart-drop-down-menu>
                          </div>
                        </header>

                        <div class="stats__big-number-wrapper">
                          <div class="stats__big-number stats__big-number--css">
                            {{ myOfferAcceptance() | number:'1.0-2' }}
                            <span class="stats__big-number-percent">%</span>
                          </div>
                        </div>

                        <footer class="stats__footer--css">
                          <div class="stats__item-header">
                            <span class="stats__line"></span>
                            <span class="stats__item-header-text fs-unmask">IN PERIOD</span>
                            <span class="stats__line"></span>
                          </div>
                          <div class="stats__items">
                            <div class="stats__item">
                              <span class="stats__item--label">
                                {{ analyticsData.my_hires_total.value }}
                              </span>
                              <span class="stats__item--value fs-unmask">
                                HIRED
                              </span>
                            </div>
                            <div class="stats__item" (click)="openMyOffersDeclinedModal()">
                              <span class="stats__item--label">
                                {{ analyticsData.my_offers_rejected.value }}
                              </span>
                              <span class="stats__item--value fs-unmask" id="declinedSpan" ngbTooltip="Offer Rejected">
                                DECLINED
                              </span>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>

                  <div class="dashboard__grid--css">
                    <twng-dash-bar-chart *ngIf="analyticsData.my_submitted_scorecards"
                      class="dashboard__grid-item dashboard__grid-item--span-3"
                      label="My Submitted Scorecards [not filtered]" [chartFilters]="analyticsData.my_dashboard_filters"
                      gridItemType="css" [data]="analyticsData.my_submitted_scorecards" chartDirection="vertical" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_hires_per_month"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Hires per Month"
                      gridItemType="css" [chartFilters]="analyticsData.my_dashboard_filters" chartDirection="vertical"
                      [data]="analyticsData.my_hires_per_month" [candidateDataType]="widgetDataTypes.HIRED" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_hires_by_department"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Hires by Department"
                      [chartFilters]="analyticsData.my_dashboard_filters" chartDirection="horizontal" gridItemType="css"
                      [data]="analyticsData.my_hires_by_department" [candidateDataType]="widgetDataTypes.HIRED" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>

                    <twng-dash-pie-chart *ngIf="analyticsData.my_hires_by_source"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Hires by Source Type"
                      gridItemType="css" [chartFilters]="analyticsData.my_dashboard_filters"
                      [data]="analyticsData.my_hires_by_source" [candidateDataType]="widgetDataTypes.HIRED">
                    </twng-dash-pie-chart>

                    <twng-dash-pie-chart *ngIf="analyticsData.my_offers_by_source"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Offers by Source Type"
                      gridItemType="css" [chartFilters]="analyticsData.my_dashboard_filters"
                      [data]="analyticsData.my_offers_by_source" [candidateDataType]="widgetDataTypes.OFFER">
                    </twng-dash-pie-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_hires_by_source_name"
                      class="dashboard__grid-item dashboard__grid-item--span-3" chartDirection="horizontal"
                      gridItemType="css" label="My Hires by Source" [candidateDataType]="widgetDataTypes.HIRED"
                      [chartFilters]="analyticsData.my_dashboard_filters" [data]="analyticsData.my_hires_by_source_name" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_offers_by_source_name"
                      class="dashboard__grid-item dashboard__grid-item--span-3" chartDirection="horizontal"
                      gridItemType="css" label="My Offers by Source" [candidateDataType]="widgetDataTypes.OFFER"
                      [chartFilters]="analyticsData.my_dashboard_filters" [data]="analyticsData.my_offers_by_source_name" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_time_to_hire_by_location"
                      class="dashboard__grid-item dashboard__grid-item--span-3" chartDirection="horizontal"
                      gridItemType="css" label="My Time To Hire by Office"
                      [chartFilters]="analyticsData.my_dashboard_filters" [candidateDataType]="widgetDataTypes.HIRED"
                      [data]="analyticsData.my_time_to_hire_by_location" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_applications_by_rejection_reason"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Rejection Reasons"
                      gridItemType="css" [chartFilters]="analyticsData.my_dashboard_filters"
                      [data]="analyticsData.my_applications_by_rejection_reason" chartDirection="horizontal" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>
                  </div>
                </section>

                <section id="my_active_pipeline">
                  <div class="section__header border-bottom-0">
                    <h1 class="section__header-label border-bottom-0">My Active Pipeline</h1>

                    <div *ngIf="!currentExternalUser" class="alert alert-warning fs-unmask">
                      No Greenhouse user was found matching your email address. My Dashboard cannot be shown.
                    </div>
                  </div>

                  <twng-widget-library-tab-header [panel]="myDashboard" [dashboardElementId]="'my_active_pipeline'">
                  </twng-widget-library-tab-header>

                  <div class="dashboard__grid--css">
                    <twng-dash-pie-chart *ngIf="analyticsData.my_activity"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Candidate Freshness"
                      gridItemType="css" [data]="analyticsData.my_activity" [customColors]="activityColors">
                    </twng-dash-pie-chart>

                    <twng-dash-pie-chart *ngIf="analyticsData.my_applications_by_source"
                      class="dashboard__grid-item dashboard__grid-item--span-3" label="My Candidates by Source Type"
                      gridItemType="css" [data]="analyticsData.my_applications_by_source">
                    </twng-dash-pie-chart>

                    <twng-dash-bar-chart *ngIf="analyticsData.my_applications_by_source_name" gridItemType="css"
                      class="dashboard__grid-item dashboard__grid-item--span-3" chartDirection="horizontal"
                      label="My Candidates by Source" [data]="analyticsData.my_applications_by_source_name" [chartInitConfig]="chartInitConfig">
                    </twng-dash-bar-chart>
                  </div>
                </section>
              </ng-container>
            </ng-template>
          </li>

          <li *ngFor="let tab of (dashboardTabs$ | async); trackBy: trackById" [ngbNavItem]="(tab.id).toString()">
            <a ngbNavLink [routerLink]="tabLink(tab)">
              <span  [ngClass]="{'tw-pr-3' : tab.editable || tab.receivers_name?.length}">{{ tab.name }}</span>

              <span *ngIf="tab.editable" [ngbTooltip]="'This dashboard is owned by ' + tab.owner_name">
                <i class="fa-solid fa-user fa-sm tw-text-cq-gray-400"></i>
              </span>

              <span *ngIf="tab.receivers_name.length" [ngbTooltip]="'Dashboard shared with edit access to: ' + tab.receivers_name.slice(0, 5).join(', ') + (tab.receivers_name.length > 5 ? ' + ' + (tab.receivers_name.length - 5) : '')">
                <i class="fa-solid fa-users fa-sm tw-text-cq-gray-400"></i>
              </span>
            </a>

            <ng-template ngbNavContent>
              <twng-custom-dashboard [user]="currentUser" [tab]="tab" [exporting]="exportingPdf$ | async"></twng-custom-dashboard>
            </ng-template>
          </li>

          <li class="nav-item" (click)="clickNewTab()">
            <a class="nav-link" role="tab">
              <span ngbTooltip="New Dashboard" placement="top auto"><i class="fas fa-plus icon--add"></i></span>
            </a>
          </li>

          <li *ngIf="tabsCount > 1" class="nav-item" (click)="clickReorderTabs()">
            <a class="nav-link" role="tab">
              <i class="fas fa-random icon--reorder" ngbTooltip="Reorder Tabs" placement="top auto"></i>
            </a>
          </li>
        </ng-container>
      </ul>

      <div class="app__tab-outlet" [ngbNavOutlet]="dashboardsNav"></div>
    </ng-template>
  </li>

  <li ngbNavItem="candidates-by-stage" *ngIf="currentUser.enable_allegro_wall">
    <a ngbNavLink>Candidates by Stage</a>
    <ng-template ngbNavContent>
      <div class="candidates-by-stage-container">
        <twng-candidates-in-stage></twng-candidates-in-stage>
      </div>
    </ng-template>
  </li>
  
  <li ngbNavItem="quin" *ngIf="currentUser.can_view_gpt">
    <a ngbNavLink routerLink="/dashboard/quin" class="fs-unmask"><img src="/assets/quin.png" width="63"></a>
    <ng-template ngbNavContent>
      <twng-gpt [canViewDebug]="currentUser.alpha_features"></twng-gpt>
    </ng-template>
  </li>

  <!--
  <li *ngFor="let link of dashboardExternalLinks() | keyvalue" [ngbNavItem]="link.key">
    <a ngbNavLink>{{ link.value }}</a>
  </li> -->
</ul>

<div class="app__tab-outlet fs-unmask" [ngbNavOutlet]="dashboardTopNav"></div>

<div *ngIf="!(analyticsData$ | async)?.loaded" class="my-5">
  <i class="mx-auto text-center d-block fas fa-sync fa-3x fa-spin"></i>
</div>

<twng-spinner-overlay [exporting]="exportingPdf$ | async"></twng-spinner-overlay>
<ng-template #spinner>
  <twng-spinner class="d-flex justify-content-center"></twng-spinner>
</ng-template>